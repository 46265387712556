import React from 'react';
import Data from '../data/Data';
import '../css/WeddingWebsite.css';
import '../fonts/Mr_Eaves_Sans_Webfont/stylesheet.css';
import '../fonts/Butler_Webfont/stylesheet.css';
import PageSelector from './PageSelector';
import PageDisplayer from './PageDisplayer';

export class WeddingWebsite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 'HomePage',
      menuItems: [
        {label: "home", link: "HomePage"},
        {label: "RSVP", link: "RsvpPage"},
        {label: "FAQs", link: "FaqPage"},
        {label: "registry", link: "RegistryPage"}
      ],
      page: {
        documents: [
          {
            title: "",
            type: "post",
            data: {
              encoding: "html",
              body: "<p>Loading...</p>"
            }
          }
        ]
      },
      cachedPages: []
    };

    this.setState = this.setState.bind(this);
  }

  componentDidMount() {
    var page = Data.GetPage(this.state.currentPage);
    this.setState({page: page});
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.loadPage(this.state.currentPage);
    }
  }

  loadPage(pageId) {
    var page = Data.GetPage(pageId);
    this.setState({page: page});
  }
  
  render() {
    return (
      <>
        <PageSelector currentPage={this.state.currentPage} menuItems={this.state.menuItems} setState={this.setState}/>
        <PageDisplayer currentPage={this.state.currentPage} page={this.state.page}/>
      </>
    );
  }
}

export default WeddingWebsite;