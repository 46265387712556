import React from 'react';

/* Expects  
    key
    title
    date
    location
    description
    documents
    isOpen
    onClick
    onMouseOn
    onMouseOff
*/

const ScheduleItem = (props) => (
    <div className={'ScheduleItem' + (props.isOpen ? ' isOpen' : '')} onClick={props.onClick}>
        <div className='floralMark'></div>
        <div className='time'>{(() => {
            let date = new Date(props.date);
            let timeString = date.getHours() + ':' + (date.getMinutes() === 0 ? '00' : date.getMinutes());
            return timeString;
        })()}</div>
        <div className='twoLiner'>
            <div>{props.title}</div>
            <div>{props.location}</div>
        </div>
        <div className='dividingLine'></div>
        {//<div className='detailsContainer'>
        //    <div className='description'><p>{props.description}</p></div>
        //    {/* Currently only supporting document type of Post */}
        //    {props.documents.map((document) => 
        //        <Post title={document.title} body={document.data.body} />
        //    )}
        //</div>
        }
    </div>
);

export default ScheduleItem;