// Just using switch statements for now, will fetch from database in a later commit.
const FaqPage = require('../pages/FaqPage.json');
const HomePage = require('../pages/HomePage.json');
const NotFoundPage = require('../pages/NotFoundPage.json');
const RegistryPage = require('../pages/RegistryPage.json');
const RsvpPage = require('../pages/RsvpPage.json');
const SchedulePage = require('../pages/SchedulePage.json');

export default class Data {
    static GetPage(id) {

        // This only created a file entity, but didn't get the contents of the file.
        /* var file = fs.open('../pages/' + id + '.json', 'r', (err, fd) => {
            if (err) throw err;
            fs.fstat(fd, (error, stat) => {
                if (err) throw err;
                // use stat

                // always close the file descriptor
                fs.close(fd, (err) => {
                    if (err) throw err;
                });
            });
        }); */

        // Wasn't able to read using fs while browsing, presumably because the environment has changed and we no longer have acces to node.js or to the src file structure.
        /*var file = fs.readFileSync('../pages/' + id + '.json');
        var object = JSON.parse(file);*/

        switch(id) {
            case 'FaqPage':
                return FaqPage;
            case 'HomePage':
                return HomePage;
            case 'RegistryPage':
                return RegistryPage;
            case 'RsvpPage':
                return RsvpPage;
            case 'SchedulePage':
                return SchedulePage;
            default:
                return NotFoundPage;
        }
    }

    // For use with Mongo
    /*
    static SubmitRsvp(rsvp) {
        return new Promise((resolve, reject) => {
            fetch('http://localhost:31415/Activity/SaveRsvpResponse', {
                method: 'POST',
                accept: 'application/json',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({
                    name: rsvp.name,
                    isAccepts: rsvp.isAccepts,
                    guests: rsvp.guests,
                    comments: rsvp.comments,
                    email: rsvp.email
                })
            }).then(() => {resolve('success')})
            .catch(() => {reject('error')}); 
        });
    }
    */

   static nameStringToNameObject = (nameString) => {
    let nameArray = nameString.split(' ');
    return {
        'first': nameArray.shift(),
        'last': nameArray.pop(),
        'middles': nameArray
    }
}

    // For use with Firestore
    static SubmitRsvp(rsvp) {
        return new Promise((resolve, reject) => {

            let docToSave = {
                rsvp: {
                    comments: rsvp.comments,
                    email: rsvp.email,
                    fellowGuests: rsvp.guests.map(guest => this.nameStringToNameObject(guest)),
                    invitedGuest: this.nameStringToNameObject(rsvp.name),
                    isAttending : rsvp.isAccepts
                }
            };

            fetch('https://us-central1-aly-and-ben.cloudfunctions.net/saveRsvp', {
                method: 'POST',
                accept: 'application/json',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify(docToSave)
            }).then(() => {resolve('success')})
            .catch(() => {reject('error')}); 
        });
    }
}