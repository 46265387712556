import React from 'react';
import Data from '../data/Data';

export class RsvpForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            isAccepts: null,
            guests: [],
            comments: '',
            email: '',
            submitButtonText: 'SEND',
            isSubmitted: false
        }

        this.setState = this.setState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleAreaChange = this.handleAreaChange.bind(this);
    }
    handleSubmit() {
        if (!this.state.isSubmitted) {
            this.setState({submitButtonText:'SENDING...'});
            Data.SubmitRsvp(this.state)
                .then((resonse) => this.setState({
                    submitButtonText: 'THANK YOU',
                    isSubmitted: true
                }))
                .catch((error) => this.setState({submitButtonText: 'error'})
            );
        } 
    }
    handleChange(event) {
        var stateItem = {};
        stateItem[event.target.name] = event.target.value;
        this.setState(stateItem);
    }
    handleOptionChange(event) {
        this.setState({isAccepts: Boolean(event.target.value === 'true')});
    }
    handleAreaChange(event) {
        this.setState({guests: String(event.target.value).split(/,|\n|\r/)});
    }
    render() {
        return (<>
        <h1>{this.props.title}</h1>
        <div className="RsvpForm">
            { (() => {
                if(this.state.isSubmitted) { return (<>
                    <div style={{textAlign: 'center'}}>
                        <p>Your RSVP is sent, thank you so much for replying!</p>
                        <p>To send us any updates you may call or text us at:</p>
                        <p>(480) 249-6393</p>
                    </div>
                    <div className="bottomEdgeButton" onClick={this.handleSubmit}>
                        <div className="innerBorder">{this.state.submitButtonText}</div>
                    </div>
                </>)}
                else { return (<>
                    <div>Please respond by October 31st so that we may be ready to seat you and your guests.</div>
                    <input type="text" placeholder="Your Full Name Here" name="name" value={this.state.name} onChange={this.handleChange}></input>
                    <label className="customRadio">
                        <input type="radio" name="isAccepts" value={true} checked={this.state.isAccepts === true} onChange={this.handleOptionChange}></input> Cordially Accepts
                        <span className="radioButton"></span>
                    </label>
                    <label className="customRadio">
                        <input type="radio" name="isAccepts" value={false} checked={this.state.isAccepts === false} onChange={this.handleOptionChange}></input> Regretfully Declines
                        <span className="radioButton"></span>
                    </label>
                    <div style={{display: this.state.isAccepts? 'block' : 'none'}}>
                        <div>And will be in the company of the following guests:</div>
                        <textarea placeholder={"One Guest Here\nAnother Guest Next Line\nAnd So On"} name="guests" value={this.state.guests.join('\n')} onChange={this.handleAreaChange}></textarea>
                        <div>Additional comments:</div>
                        <textarea placeholder={"Any special accommodations, warm fuzzy kind thoughts etc."} name="comments" value={this.state.comments} onChange={this.handleChange}></textarea>
                        <div>Reachable by Email at:</div>
                        <input type="text" name="email" placeholder="YourEmailAddress@somewhere.com" value={this.state.email} onChange={this.handleChange}></input>
                        <div>(Adding your email address helps us send you the schedule, a local guide, and any important updates)</div>
                    </div>
                    <div className="bottomEdgeButton" onClick={this.handleSubmit}>
                        <div className="innerBorder">{this.state.submitButtonText}</div>
                    </div>
                </>)}
            })()}
        </div>
    </>);
    }
}

export default RsvpForm;