import React from 'react';
import ComponentMap from '../data/ComponentMap';
import DocumentTypes from '../enums/DocumentTypes';
import PageArea from './PageArea';
import Post from './Post';
import Schedule from './Schedule';
import Footer from './Footer';


const PageDisplayer = (props) => {
    return (
        <PageArea>
            {
                props.page.documents.map((document, index) => {
                    switch (document.type) {
                        case DocumentTypes.Component:
                            const Comp = ComponentMap[document.data.body.id];
                            return <Comp key={index} {...document.data.body.props} title={document.title}/>;
                        case DocumentTypes.Post:
                            return <Post key={index} title={document.title} body={document.data.body} />;
                        case DocumentTypes.Schedule:
                            return <Schedule key={index} title={document.title} items={document.data.items} />;
                        default:
                            return null;
                    }
                })
            }
            <Footer></Footer>
        </PageArea>
    )
}

export default PageDisplayer;