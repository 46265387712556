import React from 'react';

export default class Registry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullRegistryLink: "https://www.simpleregistry.com/alyandben/"
        }

        this.setState = this.setState.bind(this);
    }

    render() { return(
        <div className="RegistryGallery">
            <h1>{this.props.title}</h1>
            <a href={this.state.fullRegistryLink}>
            <span className="CallToActionButton">
                <span className="innerBorder" >VISIT THE FULL REGISTRY</span>
            </span>
            </a>
        </div>
    );}
}