import React from 'react';
import HeaderArea from './HeaderArea';
import HeaderLogo from './HeaderLogo';
import Nav from './Nav';
import NavArea from './NavArea';
import NavItem from './NavItem';


export class PageSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0,
            isMenuOpen: false
        };

        this.setState = this.setState.bind(this);
        this.selectPage = this.selectPage.bind(this);
        this.showHideMenu = this.showHideMenu.bind(this);
    }

    componentDidMount() {
        this.setState({selectedIndex: this.props.menuItems.findIndex((item) => item.link === this.props.currentPage)});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState({
                selectedIndex: this.props.menuItems.findIndex((item) => item.link === this.props.currentPage)
            });
        }
    }

    selectPage(navItemIndex) {
        let newCurrentPageId = this.props.menuItems[navItemIndex].link;
        this.props.setState({currentPage: newCurrentPageId, selectedIndex: navItemIndex});
        this.setState({isMenuOpen: false});
    }

    showHideMenu() {
        let prevState = this.state.isMenuOpen;
        this.setState({isMenuOpen: !prevState});
    }

    render() {
        return (
            <HeaderArea>
                <HeaderLogo isMenuOpen={this.state.isMenuOpen} onClick={this.showHideMenu}/>
                <NavArea>
                    <Nav isMenuOpen={this.state.isMenuOpen} onClick={this.showHideMenu}>
                        {this.props.menuItems.map((item, index) => 
                            <NavItem key={index} label={item.label} selected={index === this.state.selectedIndex ? true : false} onClick={()=>{this.selectPage(index)}} />  
                        )}
                    </Nav>
                </NavArea>
            </HeaderArea>
        );
    }
}

export default PageSelector;