import React from 'react';

const Post = (props) => {
    return(
        <div className='Post'>
            <h1>{props.title}</h1>
            <div dangerouslySetInnerHTML={{__html:props.body}}></div>
        </div>
    )
}

export default Post;