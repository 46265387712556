import React from 'react';
import ScheduleItem from './ScheduleItem';
import '../css/Schedule.css';

export default class Schedule extends React.Component {
    constructor(props) {
        super(props); /* Expects:
        title: the title of the schedule
        items: a list of schedule item data objects
        */

        this.state = {
            keepOpen: false, // Controls whether details fade away on hover-off
            highlightedIndex: null,
            openIndex: null,
        }

        this.setState = this.setState.bind(this);
        this.openItem = this.openItem.bind(this);
    }

    openItem(index) {
        this.setState({openIndex: index});
    }

    render() {
        return (
            <div className='Schedule'>
                <h1>{this.props.title}</h1>
                <div className='ScheduleItemsContainer'>
                    {this.props.items.map((item,index) =>
                        <ScheduleItem 
                            key={index}
                            title={item.title} 
                            date={item.date}
                            location={item.location} 
                            description={item.description} 
                            documents={item.documents}
                            isOpen={index === this.state.openIndex}
                            onClick={() => {this.openItem(index);}}
                            // onMouseOn
                            // onMouseOff
                        />
                    )}
                </div>
            </div>
        )
    }
}