import React from 'react';

const NavItem = (props) => (
    
    <div className={'NavItem' + (props.selected ? ' selected' :'')} onClick={props.onClick} >
        <div className='NavItemText'>
            {props.label}
        </div>
    </div>
);

export default NavItem;