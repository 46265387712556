import React from 'react';

const Nav = (props) => {
    let dispWithMenu = {display: props.isMenuOpen ? 'block' : 'none'}
    return (<>
        <div className='Nav desktop'>
            {props.children}
        </div>
        <div className='Nav closeZone' style={dispWithMenu} onClick={props.onClick}></div>
        <div className='Nav mobile' style={dispWithMenu}>
            {props.children}
        </div>
    </>)
};

export default Nav;
